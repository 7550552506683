import React, { Component } from "react";
import { Link } from "react-router-dom";

import Stations from "../../Common/Stations";
import StationsUS from "../../Common/StationsUS";
import { SliderText } from "./SliderText";
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import Laptop from "../../Assets/images/laptop_agm-design-tool.png";
import youtubeLogo from "../../Assets/images/youtube-icon.png";
import LaptopMobile from "../../Assets/images/laptop_agm-design-tool_mobile.png";
import blueCheckmark from "../../Assets/images/checkmark-blue.png";
import whiteLarge from "../../Assets/images/banner-white-background-blob.png";
// import ModalVideo from 'react-modal-video'
import VideoModal from "../../Common/videoModal";
// import ReactPlayer from "react-player";
// import { render } from "@testing-library/react";

// export const TopSection = (props) => {
// const tool3dRegions = ['ottawa', 'gta', 'calgary', 'vancouver'];

// const getStations = async (stationsCountry, that) => {

//     const stationsToLoad = {
//       CA:  () => import('../../Common/Stations'),
//       US: () => import('../../Common/StationsUS')
//     }

//     if (!stationsToLoad[stationsCountry]) stationsCountry = 'CA';
//     that.state.countryCode = stationsCountry;
//     console.error('that.state.countryCode', that.state.countryCode);
//     const mod = await stationsToLoad[stationsCountry]();
//     console.error('thmod.defaulte', mod.default);
//     that.Stantions = mod.default;
// }
// laptopVideo: 's0hz5E1E2ZU',
// const videoId = countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA';

export class TopSection extends Component {
  constructor(props) {
    super();
    this.state = {
      isOpen: false,
      countryCode: props.location?.countryCode,
    };
    this.videos = {
      laptopVideo:
        this.state.countryCode === "CA" ? "0_Rr23cnD0Q" : "TeXeepjTWKA",
      HGTV: "5kVBooRtR4Q",
    };
    this.openModal = this.openModal.bind(this);
    this.handleHGTVStar = this.handleHGTVStar.bind(this);
    this.Stantions = null;
  }

  componentDidUpdate() {
    const countryCode = this.props.location?.countryCode;

    if (countryCode && this.state.countryCode !== countryCode) {
      this.setState({ countryCode });
      this.videos = {
        laptopVideo: countryCode === "CA" ? "0_Rr23cnD0Q" : "TeXeepjTWKA",
        HGTV: "5kVBooRtR4Q",
      };
    }
  }

  openModal() {
    this.setState({ isOpen: true, videoId: this.videos.laptopVideo });
  }

  handleHGTVStar() {
    this.setState({ isOpen: true, videoId: this.videos.HGTV });
  }

  render() {
    const {
      title,
      subtitle,
      listTitle,
      listItems,
      backgroundImage,
      className,
      children,
    } = this.props;
    const isKitchenApp = process.env.REACT_APP_NAME === "kitchens";

    // if(region==='calgary' || region==='vancouver' ){
    if (!isKitchenApp && false) {
      return (
        <>
          <section className="home-section">
            <section className="home-banner">
              <div className="welcome-text">
                <h1>Bathroom Renovations</h1>
                <div className="bottom-items">
                  <span></span>Done Right!
                </div>
              </div>

              {this.state.isOpen && (
                <VideoModal
                  isOpen={this.state.isOpen}
                  videoId={this.state.videoId}
                  handleClose={() => {
                    this.setState({ isOpen: false });
                  }}
                />
              )}

              <div className="d-flex checkmark-box">
                <div className="laptop-box">
                  <div className="desktop-visible">
                    <img
                      onClick={this.openModal}
                      className="youtube-icon"
                      src={youtubeLogo}
                    />
                    <img
                      onClick={this.openModal}
                      className="laptop-img"
                      src={Laptop}
                    ></img>
                  </div>
                  <div className="mobile-visible laptop">
                    <img
                      onClick={this.openModal}
                      className="youtube-icon"
                      src={youtubeLogo}
                    />
                    <img
                      onClick={this.openModal}
                      className="laptop-img-mobile"
                      src={LaptopMobile}
                    ></img>

                    <div className="footerInfoBar mobile">
                      <div className="itemInfo">
                        <b className="border">Ivan A.</b>
                        <span className="border">CEO AGM Renovations</span>
                      </div>
                      <div
                        onClick={this.handleHGTVStar}
                        className="itemInfo hovered"
                      >
                        <b>Mike Holmes</b>
                        <span>
                          <strong>HGTV</strong> Star & Contractor
                        </span>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-box">
                  <img src={whiteLarge} className="white-blob" />
                  <h4>NO NEED TO</h4>
                  <ul className="checkmark-list">
                    <li>
                      <img src={blueCheckmark} className="float-left" />
                      <b>Deal with contractors</b>
                    </li>
                    <li>
                      <img src={blueCheckmark} className="float-left" />
                      <b>Invite strangers to your home</b>
                    </li>
                    <li>
                      <img src={blueCheckmark} className="float-left" />
                      <b>Drive to multiple showrooms</b>
                    </li>
                    <li>
                      <img src={blueCheckmark} className="float-left" />
                      <b>And no more wasted time!</b>
                    </li>
                  </ul>
                  {/* <Link to="/design" className="blue-btn desktop-btn">
                    Get Free Quote
                  </Link> */}
                  <Link to="/get-started" className="blue-btn desktop-btn">
                    Free Consultation
                  </Link>
                </div>
              </div>

              <div className="footerInfoBar desktop">
                <div className="itemInfo">
                  <b className="border">Ivan A.</b>
                  <span className="border">CEO AGM Renovations</span>
                </div>
                <div onClick={this.handleHGTVStar} className="itemInfo hovered">
                  <b>Mike Holmes</b>
                  <span>
                    <strong>HGTV</strong> Star & Contractor
                  </span>
                  <hr />
                </div>
              </div>
            </section>
          </section>
          <SliderText showButton={true} />




          {/* {this.state.countryCode === "CA" ? <Stations /> : <StationsUS />} */}
          {/* {this.Stantions && <this.Stantions />} */}
        </>
      );
    } else {
      // TODO move welcome-text up on < 1100px width
      return (
        <>
          <section
            className={`home-section modified ${className ? className : ""}`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
              marginTop: "-220px",
              backgroundSize: "100%",
            }}
            //  backgroundPositionY: '0px',
          >
            <div className="banner-cover" />
            <section className="home-banner">
              <div className="welcome-text">
                <h1>{title}</h1>
                <p className="welcome-sub">{subtitle}</p>
                <h4>{listTitle}</h4>
                <ul>
                  {listItems &&
                    listItems.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
                <Link to="/get-started" className="blue-btn desktop-btn">
                  Free Consultation
                </Link>
              </div>
              {this.state.isOpen && (
                <VideoModal
                  isOpen={this.state.isOpen}
                  videoId={this.state.videoId}
                  handleClose={() => {
                    this.setState({ isOpen: false });
                  }}
                />
              )}
              <div className="footerInfoBar" style={{ marginRight: "2vw" }}>
                <div className="itemInfo">
                  <b className="border">Ivan A.</b>
                  <span className="border">CEO AGM Renovations</span>
                </div>
                <div
                  onClick={this.handleHGTVStar}
                  className="itemInfo hovered"
                  style={{ paddingLeft: 0, marginLeft: "2vw" }}
                >
                  <b>Mike Holmes</b>
                  <span style={{ padding: 0 }}>
                    <strong>HGTV</strong> Star & Contractor
                  </span>
                  <hr />
                </div>
              </div>
            </section>
            {children}
          </section>

          {/* {this.Stantions && <this.Stantions />} */}
          {/* <SliderText showButton={false} /> */}
          {/* {this.state.countryCode === "CA" ? <Stations /> : <StationsUS />} */}
        </>
      );
    }
  }
}
export default TopSection;
