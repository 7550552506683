import React from "react";
import { Link } from "react-router-dom";
import { DoorsNav } from "../../DoorsNav/DoorsNav";
import Ivan from "../../../Assets/images/windows/home/ivan-top-banner.png";
import Mike from "../../../Assets/images/windows/home/mike-top-banner.png";
import ArrowRight from "../../../Assets/images/windows/home/arrow-right.png";
import CloseIcon from '@mui/icons-material/Close';

import "./WindowsHomeTop.scss";

export const WindowsHomeTop = ({ doorMenu }) => {
  return (
    <section className="windowsHomeTop">
      <DoorsNav doorMenu={doorMenu} />
      <div className="topBanner">
        {/* <div className="ivanSection">
          <img src={Ivan} alt="Ivan" />
          <div className="ivanSection__description">
            <h3>Ivan Atanasov</h3>
            <h4>CEO AGM Renovations</h4>
          </div>
        </div> */}
        <div className="textSection">
          <h2>Windows and Doors Done Your Way!</h2>
          <h3>
            Transform your home with top-quality windows and doors that stand
            out.
          </h3>
          <Link to="/get-started" className="blue-dark-windows-btn">
            <button>GET FREE QUOTE</button>
          </Link>
          <h4 className="welcomeBottomn">
            Welcome to AGM Renovations - Your Window and Door Experts!
          </h4>
        </div>
        {/* <div className="mikeSection">
          <img src={Mike} alt="Mike" />
          <div className="mikeSectionDescription">
            <h3>Mike Holmes</h3>
            <span>
              <h4>HGTV Star & Contractor</h4>
              <img className="mikeSection__arrow" src={ArrowRight} />
            </span>
          </div>
        </div> */}


        <div className="promoStamp">
          <button onClick={(e)=> { e.currentTarget.parentElement.style.display = 'none'; }} className="closeBtn">
            <CloseIcon/>
          </button>
          <p className="topPar">
            <span>
              Save %15 on Windows & Doors installation
            </span>
          </p>

          <p className="middleOffer desk">
          <strong>No</strong> Money Down <br/>
          <strong>No</strong> Interest <br/>
          <strong>No</strong> Payments for 1 Year*
          </p>
          <p className="middleOffer mobile">
          <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year*
          </p>

          <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image desk">
                <strong>the installation is included</strong>
              </p>

              <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image mobile">
                <strong>the installation is included</strong>
              </p>

            <Link sx={{ color: '#fff', fontWeight: 700 }} to="/get-started?offer=1" className="blue-dark-windows-btn">
            <div style={{ color: '#fff', fontWeight: 700 }} className="button">
              See Offer
            </div>
            </Link>

          <span className="endPart">Offer ends 12-31-24</span>
        </div>
        
      </div>
    </section>
  );
};
