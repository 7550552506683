import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";

import axios from "axios";
import { API_URL } from "../../Constants/default";
import "./GetStarted.scss";

class GetStarted extends Component {
  static propTypes = {};

  state = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    email: "",
    phone: "",
    theSource: "",
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  processGetStartedForm = (e) => {
    // const url = 'https://bathroom-api.herokuapp.com/customers';
    e.preventDefault();
    const url = "https://hooks.zapier.com/hooks/catch/2915270/bbkhce2/";
    let todayDate = new Date().toISOString().slice(0, 10);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        // 'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        full_name: this.state.firstName + " " + this.state.lastName,
        email: this.state.email,
        city: this.state.city,
        phone: this.state.phone,
        date: todayDate,
      }),
    }).then((res) => {
      if (res.ok === true) {
        window.location = "/thank-you";
      }

      return;
    });
  };

  // processGetStartedForm = async (e) => {
  //   e.preventDefault();
  //   const res = await axios.post(`${API_URL}/customers`, {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     first_name: this.state.firstName,
  //     last_name: this.state.lastName,
  //     city: this.state.city,
  //     email: this.state.email,
  //     phone: this.state.phone,
  //   });
  //   if (res.data.success) {
  //     sessionStorage.setItem("customer_id", res.data.customer_id);
  //     this.props.history.push("/finishes");
  //   }
  // };

  componentDidMount() {
    let vars = {};
    const setState = (props) => this.setState(props);

    const rendercaptha = () => {
      window.grecaptcha.render('recaptcha-getstarted', {
        'sitekey' : '6Ld2nZcqAAAAANYPPhNrsIqSTOC8EfmHyuwGjCmk',
        'callback' : function(token) {
          // console.log('callback', token, );

          fetch('https://oyqfdcs475e7tai5shl43ieeje0msfvb.lambda-url.us-east-1.on.aws/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              token
            })
          })
          .then(resp => resp.json())
          .then(result => {
           
            if (result && result.success && setState) {
              setState({
                isAllowedToSubmit: true
              });
            }

          })
          .catch((e) => {
            console.error(e);

            if (e !== 'Failed to fetch') {
              setState({
                isAllowedToSubmit: true
              });
            }
              
          });

        }
      });
    };

    const checkCaptchaLoaded = () => {
      if (window.grecaptcha && window.grecaptcha.render) {
        rendercaptha();
      } else {
        setTimeout(() => {      
          checkCaptchaLoaded();
        }, 1000);
      }
    };
    checkCaptchaLoaded();

    const parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
        if (localStorage) {
          localStorage.setItem("vars", JSON.stringify(vars));
        }
      }
    );

    if ("agmsource" in vars) {
      sessionStorage.setItem("agmsource", vars.agmsource);
    }

    const theSource = localStorage.getItem("vars");
    const parsedSource = theSource && JSON.parse(theSource).agmsource;

    const possibleSources = [
      "1310 news",
      "580 AM",
      "AM900 (Hamilton)",
      "CHCH TV",
      "CHML",
      "CKTB",
      "Company vehicle",
      "CP24",
      "CTV",
      "Home Show",
      "AdWords",
      "Internet Search",
      "Magazine/flier",
      "News Talk 610 (Niagara)",
      "680 News",
      "Referral from previous client (CSR team will collect contact info once connected to the customer)",
      "Social Media",
      "Facebook",
      "Instagram",
      "YouTube",
      "Pinterest",
      "3C Contact Services.",
      "Nexa Call Center",
      "Word of mouth",
    ];

    if (possibleSources.includes(decodeURI(parsedSource))) {
      const decodedURI = decodeURI(parsedSource);
      this.setState({
        theSource: decodedURI,
      });
    }

    if (this.mount?.parentNode) {
      this.mount.parentNode.parentNode.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  

  render() {
    const isKitchenApp = process.env.REACT_APP_NAME === "kitchens";

    let showOffer = false;
    const urlSearchParamsCurrent = new URLSearchParams(window.location.search);
    const paramsCurrent = Object.fromEntries(urlSearchParamsCurrent.entries());
    if (paramsCurrent && paramsCurrent.offer) {
      showOffer = true;
    }

    return (
      <Box
        mt={8}
        mb={15}
        ref={(mount) => {
          this.mount = mount;
        }}
      >
        <Container maxWidth="sm">
          <Box className="text-center">
            <Box>
              <h2>Get Started</h2>
            </Box>
            <Box mt={2} mb={5}>
              <h4 style={{ fontWeight: 400 }}>
                Are you ready to invest in the value, appeal and quality of your
                home? Simply enter your information below and we will help get
                your project started!
              </h4>
            </Box>
          </Box>
          <form
            //ref={ref => this.formRef = ref}
            method="POST"
            id="estimateform"
            //onSubmit={this.processGetStartedForm}
            action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          >
            <input type="hidden" name="oid" value="00D5Y000002U1by" />
            <input
              type="hidden"
              name="retURL"
              value={`${process.env.REACT_APP_URL}/thank-you`}
            />

            {this.state.theSource && (
              <input
                id="lead_source"
                name="lead_source"
                type="hidden"
                value={this.state.theSource || ""}
              />
            )}

            <input type="hidden" name="Lead_Origin__c" value="Web" />

            <input
              type="hidden"
              id="Renovation_Type__c"
              name="Renovation_Type__c"
              value={"windows"}
            />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    fullWidth
                    onChange={this.handleInputChange}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    fullWidth
                    onChange={this.handleInputChange}
                    required
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={6}>
                <Box>
                  <TextField id="standard-basic" label="Address" name="address" fullWidth onChange={this.handleInputChange} />
                </Box>
              </Grid> */}
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="city"
                    label="City"
                    name="city"
                    fullWidth
                    onChange={this.handleInputChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="email"
                    label="Email"
                    name="email"
                    fullWidth
                    onChange={this.handleInputChange}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    id="phone"
                    label="Phone"
                    name="phone"
                    fullWidth
                    onChange={this.handleInputChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <p className="agreement-text">
                    By clicking "continue", you agree to our Terms/Conditions
                    &amp; <a href="/privacy">Privacy Policy</a>.
                  </p>
                </Box>
              </Grid>

              <div id="recaptcha-getstarted"></div>
              <br/>

                            <Grid item xs={6}>
                                {
                                this.state.isAllowedToSubmit 
                                ? <Box>
                                    <Fab
                                        variant='extended'
                                        color='primary'
                                        className='full-width'
                                        type='submit'
                                        style={showOffer ? { minWidth: '300px', background: '#FFA500' } : {}}
                                    >
                                       {showOffer ? "Book free Consultation" : "Continue"}
                                    </Fab>
                                    </Box>
                                : <Box>
                                    <Fab
                                        variant="extended"
                                        color="primary"
                                        className="full-width"
                                        style={showOffer ? { minWidth: '300px', background: '#bbb' } : { background: '#bbb' }}
                                        disabled={true}
                                    >
                                        {showOffer ? "Book free Consultation" : "Continue"}
                                    </Fab>
                                  </Box>
                                }
                            </Grid>

            </Grid>
          </form>
        </Container>

          {showOffer ?
            <div className="promoStampOffer">
              <p className="topPar">
                <span>
                  Save %15 on Windows & Doors installation
                </span>
              </p>

              <p className="middleOffer desk">
              <strong>No</strong> Money Down <br/>
              <strong>No</strong> Interest <br/>
              <strong>No</strong> Payments for 1 Year*
              </p>
              <p className="middleOffer mobile">
              <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year (o.a.c).*
              </p>

              <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image">
                <strong>the installation is included</strong>
              </p>

              <span className="">Offer ends 12-31-24</span>
              <br/>
              <p className="endPart">
              *Cannot be combined with previous projects or other promotions. 
              The discount will be applied by the sales agent at time of contract signing and do not apply to partial projects.   
              All financing is subject to qualify O.A.C. and is provided by third-party financing companies that are not affiliated with AGM Renovations. 
              All financing terms and conditions are arranged and directly between the customer and the financing company and are subject to credit approval. 
              </p>

            </div>
            : null
          }

      </Box>
    );
  }
}

export default GetStarted;
