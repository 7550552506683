import React, { useEffect } from "react";

import { OurAwards } from "../../Containers/Home";
import StationsUs from "../../Common/StationsUS";
import Stations   from "../../Common/Stations";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { WhatTheySayHomeWindows } from "../../Containers/Home/windows/WhatTheySayHomeWindows";
import { EnjoyNewWindow } from "../../Containers/Home/windows/EnjoyNewWindow";
import { WindowsOurLatestWork } from "../../Containers/Home/windows/WindowsOurLatestWork";
import { WindowsRedyStarted } from "../../Containers/Home/windows/WindowsRedyStarted";

import LatestWorkOne from "../../Assets/images/windows/our-works/gallery/9e.jpg";
import LatestWorkTwo from "../../Assets/images/windows/our-works/gallery/7q.jpg";
import LatestWorkThree from "../../Assets/images/windows/our-works/gallery/8w.jpg";
import LatestWorkFour from "../../Assets/images/windows/our-works/gallery/2e.jpg";
import LatestWorkFive from "../../Assets/images/windows/our-works/gallery/4.jpg";

import TestimonialsReviewerTwo from "./../../Assets/testimonials_images/pic-10.png";
import TestimonialsReviewerThree from "./../../Assets/testimonials_images/pic-08.png";

import TestimonialsReviewerFive from "./../../Assets/testimonials_images/pic-14.png";
import TestimonialsReviewerSix from "./../../Assets/testimonials_images/pic-04.png";
import TestimonialsReviewerSeven from "./../../Assets/testimonials_images/pic-16.png";
import { WindowsHomeTop } from "../../Containers/Home/windows/WindowsHomeTop";

import VinylWindows from "../../Assets/images/windows/how-it-works/vinyl-windows.png";
import EntryDoors from "../../Assets/images/windows/how-it-works/entry-doors.png";
import PatioDoors from "../../Assets/images/windows/how-it-works/patio-doors.png";
import GarageDoors from "../../Assets/images/windows/how-it-works/garage.png";
import HighestBackground from "../../Assets/images/windows/home/highest-rare-background.png";
import { HowItWorksHome } from "../../Containers/Home/windows/HowItWorksHome";

import TabletStep1 from "../../Assets/images/windows/home/howItWork-step1.png";
import LaptopStep2 from "../../Assets/images/windows/home/howItWork-step2.png";
import WindowStep3 from "../../Assets/images/windows/home/howItWork-step3.png";

import WindowIcon1 from "../../Assets/images/windows/home/window-icon-1.png";
import WindowIcon2 from "../../Assets/images/windows/home/window-icon-2.png";
import WindowIcon3 from "../../Assets/images/windows/home/window-icon-3.png";
import WindowIcon4 from "../../Assets/images/windows/home/window-icon-4.png";
import WindowIcon5 from "../../Assets/images/windows/home/window-icon-5.png";

import Window1 from "../../Assets/images/windows/home/window1.png";
import Window2 from "../../Assets/images/windows/home/window2.png";
import Window3 from "../../Assets/images/windows/home/window3.png";
import Window4 from "../../Assets/images/windows/home/window4.png";
import Window5 from "../../Assets/images/windows/home/window5.png";

const doorMenu = [
  {
    id: 0,
    img: VinylWindows,
    alt: "vinyl-windows",
    path: "vinyl-windows",
    title: "Windows",
  },
  {
    id: 1,
    img: EntryDoors,
    alt: "entry-doors",
    path: "entry-doors",
    title: "Entry Doors",
  },
  {
    id: 2,
    img: PatioDoors,
    alt: "",
    path: "patio-doors",
    title: "Patio Doors",
  },
  {
    id: 3,
    img: GarageDoors,
    alt: "",
    path: "garage-doors",
    title: "Garage Doors",
    left: "-48px",
    top: "-8px",
  },
];
const stepItems = [
  {
    id: 0,
    img: TabletStep1,
    alt: "Tablet",
    title: `Get Your Free Quote`,
    description: `Get in touch with us, and one of our expert designers will meet with you to discuss your vision for your home and create a custom quote for your project.`,
  },
  {
    id: 1,
    img: LaptopStep2,
    alt: "Laptop",
    title: `Pick Your Windows or Doors`,
    description: `We have an extensive range of windows and doors available to bring your vision to life! We’ll explore the best options for your home and you’ll get to choose your favourite ones.
    `,
  },
  {
    id: 2,
    img: WindowStep3,
    alt: "Window",
    title: `Your Installation Begins`,
    description: `Our installation techs will work to carefully remove your existing windows or doors and install your new ones, leaving you with a flawless finish and ample light!`,
  },
];

const windowIcons = [
  { id: 0, img: WindowIcon1, alt: "Window1" },
  {
    id: 1,
    img: WindowIcon2,
    alt: "Window2",
  },
  { id: 2, img: WindowIcon3, alt: "Window3" },
  {
    id: 3,
    img: WindowIcon4,
    alt: "Window4",
  },
  {
    id: 4,
    img: WindowIcon5,
    alt: "Window5",
  },
];

const windowImages = {
  0: [Window1],
  1: [Window2],
  2: [Window3],
  3: [Window4],
  4: [Window5],
};

const latestWorksItems = [
  LatestWorkOne,
  LatestWorkTwo,
  LatestWorkThree,
  LatestWorkFour,
  LatestWorkFive,
];
const checkList = [
  "Completing your project on time",
  "Never going over budget",
  "No added or hidden costs",
  "Decades of customer satisfaction",
];

const testimonialsItems = [
  {
    id: 0,
    text: `AGM did our bathroom for a massage business just in the past
    summer. Great sales and design experience. Your company's
    generosity culture showed at all stages of the project. Had
    a few hiccups with selected products not being available
    from the suppliers, but with patience we got through it all.
    You guys have very courteous and professional employees. Had
    great experiences with some of them. Can't recall their
    specific names.`,
    name: "K.T. Addo",
    photo: TestimonialsReviewerTwo,
  },
  {
    id: 1,
    text: `We hired AGM to renovate our bathroom which we are very
    happy with. From initial meeting with Andrea to working with
    Kate at the design studio on structure and design to making
    the vision come to life with Johnny the project manager.
    Johnny kept us updated throughout the process and was very
    helpful and informative.`,
    name: "Carm Ragno",
    photo: TestimonialsReviewerFive,
  },
  {
    id: 2,
    text: `AGM has finished our bathroom really well. We have a
    beautiful living area. Love the signature wall unit that AGM
    has installed. Workers are very professional. We believe AGM
    has given us a great value for the price we paid. We thank
    everyone at AGM. For the great work!`,
    name: "Josie Vierra",
    photo: TestimonialsReviewerThree,
  },
  {
    id: 3,
    text: `It was a great experience to watch our bathroom work done by
    AGM workers. Very experienced and well organized team. We
    have a nice entertainment area, a nice kitchen and now a
    very newly built beautiful bathroom. Overall we are very
    happy with our bathroom. The team at AGM has our
    recommendations to all families looking to finish their
    bathroom!`,
    name: "Kay Walter",
    photo: TestimonialsReviewerSix,
  },
  {
    id: 4,
    text: `We had our bathroom finished by AGM, and I'm completely
    happy with the result. Of the 3 estimates we had done, they
    were the cheapest AND gave us the most finished space. They
    finished the project on time, and their staff were
    knowledgeable and friendly.`,
    name: "Jan Genoch",
    photo: TestimonialsReviewerSeven,
  },
];

const HomePage = (props) => {

  let region = "";
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = "";
  }
  let city = "";
  if (sessionStorage.getItem("city")) {
    city = sessionStorage.getItem("city");
  } else {
    city = "";
  }

  return (
    <>

        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
          <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
          <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
          <meta name="robots" content="index"/>
        </Helmet>

      <WindowsHomeTop doorMenu={doorMenu} />


      <div className="promoStamp">
            {/* <button onClick={(e)=> { e.currentTarget.parentElement.style.display = 'none'; }} className="closeBtn">
              <CloseIcon/>
            </button> */}
            <p className="topPar">
              <span>
                Save %15 on Windows & Doors installation
              </span>
            </p>

            <p className="middleOffer desk">
            <strong>No</strong> Money Down <br/>
            <strong>No</strong> Interest <br/>
            <strong>No</strong> Payments for 1 Year*
            </p>
            <p className="middleOffer mobile">
            <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year*
            </p>

            <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image desk">
                  <strong>the installation is included</strong>
                </p>

                <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image mobile">
                  <strong>the installation is included</strong>
                </p>

              <Link sx={{ color: '#fff', fontWeight: 700 }} to="/get-started?offer=1" className="blue-dark-windows-btn">
              <div style={{ color: '#fff', fontWeight: 700 }} className="button">
                See Offer
              </div>
              </Link>

            <span className="endPart">Offer ends 12-31-24</span>
          </div>

      {props?.location?.countryCode === "US" ? <StationsUs /> : <Stations />}
      <EnjoyNewWindow windowIcons={windowIcons} windowImages={windowImages} />
      <HowItWorksHome stepItems={stepItems} />
      <WhatTheySayHomeWindows items={testimonialsItems} />
      <OurAwards blueHeader={true} background={HighestBackground} city={city} />
      <WindowsOurLatestWork items={latestWorksItems} />
      <WindowsRedyStarted checkList={checkList} />
    </>
  );
};

export { HomePage };
